import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';

import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { IndexComponent } from './index/index.component';
import { OwlModule } from 'ngx-owl-carousel';
import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';

import { StartupService } from './core/services/startup.service';

import { ShowMenuDirective } from './shared/show-menu.directive';
import { UrlTransformPipe } from './roles/shared/pipe/url-transform.pipe';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { FirebaseMessagingServiceService } from './shared/firebase-messaging-service.service';
import { PreviousRouteService } from './shared/previous-route.service';
import { NotfoundComponent } from './notfound/notfound.component';
import { BannerComponent } from './banner/banner.component';

export function startupServiceFactory(startupService: StartupService): Function {
  return () => startupService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    IndexComponent,
    ShowMenuDirective,
    UrlTransformPipe,
    NotfoundComponent,
    BannerComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    OwlModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  providers: [
    StartupService,
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService],
      multi: true
    },
    FirebaseMessagingServiceService,
    PreviousRouteService,
    DatePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}
