import { Component, OnInit, AfterViewInit, Renderer2, HostBinding, OnDestroy } from '@angular/core';

import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { UserService } from './core/services';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  message;
  title = 'app';
  loading: boolean;
  loadingEvent: any = null;
  constructor(private userService: UserService, private router: Router, private renderer: Renderer2) {
    this.loading = true;
  }

  $isLoggedIn: Observable<boolean>;
  @HostBinding('class.application') class = 'application';

  ngOnInit() {
    this.userService.populate();
  }

  ngAfterViewInit() {
    this.loadingEvent = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loading = true;
        this.renderer.addClass(document.body, 'body-overflow');
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        if (event.url === '/employer' || event.url === '/employee') {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        } else {
          this.loading = false;
        }
        this.renderer.removeClass(document.body, 'body-overflow');
      }
    });
  }

  ngOnDestroy() {
    this.loadingEvent.unsubscribe();
  }
}
