import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'default'
})
export class ImagePipe implements PipeTransform {

    transform(value: string): string {
      
      let image = "";
      if (value) {
        image = value;
      } else {
        image = './files/images/place_holer_2.png';
      }
       
       return image
    }
  
  

}
