/**
 * @description This services mainly deals with the user functionality like login logout profile update 
 * setting all the user functionality is done here
 */
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { ApiService } from './api.service';
import { User } from '../../model'
import { JwtService } from './jwt.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService
  ) { }

  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser$ = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();


  /**
   * @description authentication for user login and user register 
   * @param type route type like register or login
   * @param credentials value we are sending to server 
   */
  attemptAuth(type, credentials): Observable<any> {

    return this.apiService.post(`/users/${type}`, { user: credentials })
      .pipe(map(
        data => {

          if (type === 'login') {
            if (data['status'] === true && data['data'].user.status === 'active') {
              this.setAuth(data['data'].user);
            }
          } else if (type === 'change_password') {
            if (data['status']) {
              this.populate();
            }

          }
          return data
        }
      ));
  }

  /**
   * @description set the token for the login user and store the value of user in subject 
   * @param user user is object type which we are getting after successfull login
   */
  setAuth(user: User) {
    //Storing the jwt token in localStorage
    if (user.auth_token !== undefined) {
      this.jwtService.saveToken(user.auth_token)

    }

    //sending the details to subscriber of this subject
    this.currentUserSubject.next(user)

    //Setting the isAuthenticating to true
    this.isAuthenticatedSubject.next(true)

  }

  getCurrentUser(): User {

    return this.currentUserSubject.value;
  }

  /**
   * validate users abn number
   * @param abn_number 
   */
  validateAbn(abn_number) {
    return this.apiService.post(`/users/verify_abn_number`, abn_number)
  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {

    // If JWT detected, attempt to get & store user's info
    if (this.jwtService.getToken()) {
      this.apiService.get('/users/get_profile')
        .subscribe(
          data => {
            this.setAuth(data['data'].user)

          },
          err => {
            console.log(err)
            this.purgeAuth()
          }
        );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }


}
