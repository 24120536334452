import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ImagePipe } from '../roles/shared/pipe/image.pipe';
import { NotfoundComponent } from './notfound/notfound.component';
import { ChatBoxComponent } from '../shared/chat-box/chat-box.component';
import { ChatUsersComponent } from '../shared/chat-users/chat-users.component';
import { NumberOnlyDirective } from './number-only.directive';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { UserIdleModule } from 'angular-user-idle';
import { TooltipModule } from 'ng2-tooltip-directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { InternationalPhoneModule } from 'ng4-country-phone-select';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InfiniteScrollModule,
    SelectDropDownModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    AngularMultiSelectModule,
    InternationalPhoneModule,
    NgxTrimDirectiveModule,
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes)
    // and `ping` is 120 (2 minutes).
    UserIdleModule.forRoot({ idle: 7200, timeout: 60, ping: 10 })
  ],
  declarations: [ImagePipe, NotfoundComponent, ChatBoxComponent, ChatUsersComponent, NumberOnlyDirective],
  exports: [
    ImagePipe,
    NumberOnlyDirective,
    SelectDropDownModule,
    UserIdleModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AngularMultiSelectModule,
    InternationalPhoneModule,
    NgxTrimDirectiveModule,
    InfiniteScrollModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
