import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from '../core/services/api.service';
import { EmployerService } from '../roles/employer/services/employer.service';


@Injectable({
  providedIn: 'root'
})
export class MessageServiceService {

  constructor(private apiService:ApiService, private employerservice:EmployerService) { }
  public recepient = new BehaviorSubject<any>('');
  public notificationCount= new BehaviorSubject<any>('');
  public messageUser= new BehaviorSubject<any>('');

  setrecepient(recepientgetId){
    
    this.recepient.next(recepientgetId);
    if(!isNaN(Number(recepientgetId))){
      this.employerservice.getEmprofile('/conversations/get_all_messages',{recipient_id:recepientgetId}).subscribe(data=>{ }) 

    }
  }

  clearrecepient(){
    this.recepient.next(0);
  }

  getrecepient(): Observable<any>{
   return this.recepient.asObservable();
  }

    /* notification counts */

  setNotification(Notice_count){ 
    this.notificationCount.next(Notice_count);
  }

  clearNotification(){
    this.notificationCount.next('');
  }

  getNotification(): Observable<any>{
    return this.notificationCount.asObservable(); 
  }

      /* //notification counts */


  /* chat user in responsive screen */

  setmessageUser(userName){

    this.messageUser.next(userName);
  }

  clearmessageUser(){

    this.messageUser.next('');
  }
  
  getmessageUser(): Observable<any>{
    return this.messageUser.asObservable();
  }

    /* //chat user in responsive screen */
}
