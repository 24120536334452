import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';


import { ApiService } from './api.service';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class StartupService {

  private _startupData: any[];

  constructor(private apiService: ApiService, private userService: UserService) { }

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  load() {

    this._startupData = []
    return this.apiService.get('/skill_families').map(({ data }: { data }) => {
      const skills = data.skills;
      return skills.map((skill) => {
        this._startupData.push({
          id: skill.id,
          itemName: skill.name
        })
        return {
          id: skill.id,
          itemName: skill.name
        };
      });
    }).toPromise().catch(err => {

    }

    )


  }


  get startupData(): any {

    return this._startupData
  }
}