import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { JwtService,UserService } from '../services'
import { Router } from '@angular/router';
import {catchError} from "rxjs/internal/operators";


@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private jwtService:JwtService,private router:Router,private userService:UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Request-From': 'browser'
    };

    const token = this.jwtService.getToken();

    if (token) {
      headersConfig['Authorization'] = `Token ${token}`;
     }

    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request).pipe(catchError((error) => {
      //intercept the respons error
      
      this.handleAuthError(error);
      return of(error);
    }) as any);
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      //navigate /delete cookies or whatever
    
      this.userService.purgeAuth()
      this.router.navigate([`/index`]);
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return 
    }else if(err.status === 500){
      console.log('error 500')
    }
    
    throw Error;
  }


}
