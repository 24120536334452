import { Component, OnInit, ElementRef, Renderer2, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Roles } from '../../shared/enum/roles.enum';

import { UserService, ModalService } from '../../core';
import { ApiService } from '../../core/services/api.service';
import { StartupService } from '../../core/services/startup.service';
import { MessageServiceService } from '../message-service.service';
import { EmployerService } from '../../roles/employer/services/employer.service';
import { EmployeeSearchService } from '../../roles/employer/services/employee-search.service';
import { InvoicePopupService } from '../../roles/shared/invoice-popup.service';
import { environment } from '../../../environments/environment';
import { FirebaseMessagingServiceService } from '../../shared/firebase-messaging-service.service';

import { UserIdleService } from 'angular-user-idle';
import { PreviousRouteService } from '../previous-route.service';
import { COMMON } from '../../shared/constant/common.const';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],

  host: {
    '(document:click)': 'onClick($event);resetimer($event)',
    '(document:keyup)': 'resetimer($event)',
    '(document:wheel)': 'resetimer($event)'
  }
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  show = false;
  headerEqualsTo;
  roles = Roles;
  sessionExpireMessage = COMMON.sessionExpireMessage;
  @ViewChild('loginModalCandidate') loginModalCandidate: ElementRef;
  @ViewChild('loginModalEmployer') LoginModalEmployer: ElementRef;
  @ViewChild('employerModal') employerModal: ElementRef;
  @ViewChild('loader') loader: ElementRef;
  @ViewChild('thankyouModal') thankyouModal: ElementRef;
  @ViewChild('videoModal') videoModal: ElementRef;
  @ViewChild('timeOutModal') timeOutModal: ElementRef;
  @ViewChild('forgetPassword') forgetPassword: ElementRef;
  @ViewChild('signOut') signOut: ElementRef;
  @ViewChild('changePasswordModal') changePasswordModal: ElementRef;
  @ViewChild('closeModalbtn') closeModalbtn: ElementRef;
  @ViewChild('sendMessageModal') sendMessageModal: ElementRef;
  @ViewChild('viewInvoiceModal') viewInvoiceModal: ElementRef;
  @ViewChild('modalCloseButton') modalCloseButton: ElementRef;
  @ViewChild('firstTimePrivacyTerms') firstTimePrivacyTerms: ElementRef;
  @ViewChild('scrollemployee') scrollemployee: ElementRef;
  @ViewChild('scrollemployeeRes') scrollemployeeRes: ElementRef;
  @ViewChild('scrollemployer') scrollemployer: ElementRef;
  @ViewChild('scrollemployerRes') scrollemployerRes: ElementRef;
  year = new Date().getFullYear();
  message: String;
  errMessage: String;
  dropdownList: String[];
  selectedItems = [];
  dropdownSettings = {};

  isFormSubmitting: Boolean = false;
  ModalSubcription: Subscription;
  loginForm: FormGroup;
  forgetPasswordForm: FormGroup;
  UserName: string;
  showDropDownMenu = true;

  notificationMenu = true;
  changePasswordForm: FormGroup;
  messageTextBox: any = {};
  messageRecepients: Subscription;
  invoiceDetails: any = {};
  emailPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  url;
  notification_count: any;

  environmentUrl = `${environment.api_url}`;
  messagevariable: any;
  is_employer_admin: any = this.roles.Admin;
  notificationArray: Array<any> = [];
  CurrentPage: any = 1;
  returnUrl: string = null;
  privacyButton = true;
  firstTimePrivacyVar = false;
  history = '';
  previousUrl = '';
  MessageUserName: any = {};
  showBoxShadow = false;
  removeShadow = false;
  isIEOrEdge = false;
  notificationLoader = false;
  subscriptionEvents: any = null;
  sendButtonDisable = true;
  hostName: string;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    public modalService: ModalService,
    private startupService: StartupService,
    private renderer: Renderer2,
    private el: ElementRef,
    private builder: FormBuilder,
    private apiservice: ApiService,
    private userService: UserService,
    private messageservice: MessageServiceService,
    private employerservice: EmployerService,
    private invoicepopupservice: InvoicePopupService,
    private firebaseservice: FirebaseMessagingServiceService,
    private previousroute: PreviousRouteService,
    private modalservice: ModalService,
    private userIdle: UserIdleService,
    private employeeSearchService: EmployeeSearchService
  ) {
    // Checking if login or not if login what is the user role

    this.userService.currentUser$.subscribe(
      data => {
        if (Object.keys(data).length) {
          this.headerEqualsTo = data.role_name;
          this.UserName = data.full_name;
          this.is_employer_admin = data['employer_privilege'];
          this.messageservice.setNotification(data['unread_notifications']); // set notification counts

          if (data['reset_password_required'] == true) {
            this.modalService.onShowModal('changePasswordModal');
            this.modalCloseButton.nativeElement.style.display = 'none';
          } else {
            if (data['first_time_login'] == true) {
              this.modalService.onShowModal('firstTimePrivacyTerms');
              if ((document.querySelector('#privacyForms') as HTMLElement) != null) {
                (document.querySelector('#privacyForms') as HTMLElement).style.display = 'block';
              }
            }
          }
        } else {
          this.headerEqualsTo = 'none';
        }
      },
      err => (this.headerEqualsTo = 'none')
    );
    this.route.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        /* Auto logout Feature after 60 minutes */

        if (this.headerEqualsTo == 'employee' || this.headerEqualsTo == 'employer') {
          // Start watching for user inactivity.
          this.userIdle.startWatching();

          // Start watching when user idle is starting.
          this.userIdle.onTimerStart().subscribe(count => {
            const config = this.userIdle.getConfigValue();
            // Show popup 1 minute before logout
            if (count === config.timeout - 60) {
              this.modalservice.onShowModal('timeOutModal', this.sessionExpireMessage);
            }
          });

          // Start watch when time is up.
          this.userIdle.onTimeout().subscribe(() => {
            this.hideModal('timeOutModal');
            this.onLogout();
          });
          this.resetimer();
        }

        /* --//Auto logout Feature */
      }
    });
  }

  ngOnInit() {
    this.hostName = window.location.origin;

    this.subscriptionEvents = this.route.events.subscribe(res => {
      if (res instanceof NavigationEnd) {
        this.previousUrl = this.previousroute.getPreviousUrl();

        if (this.previousUrl == '/employee/booking' || this.previousUrl == '/employer/booking') {
          localStorage.removeItem('filters');
        }

        if (
          this.route.url.replace(/[0-9]/g, '') == '/employee/messages/' ||
          this.route.url.replace(/[0-9]/g, '') == '/employer/messages/'
        ) {
          if (this.route.url.substr(this.route.url.lastIndexOf('/') + 1) != undefined) {
            const params = new HttpParams().set(
              'employee_id',
              this.route.url.substr(this.route.url.lastIndexOf('/') + 1)
            );

            this.apiservice.get('/employees/get_employee_profile', params).subscribe(result => {
              if (result.status == true) {
                this.MessageUserName = result.data;
              }
            });
          }
        } else {
          this.MessageUserName = {};
        }
      }
      this.url = this.previousroute.getCurrentUrls();
      const filterUrl = this.url.replace(/[0-9]/g, '');
      const urlsArray = [
        '/employer/employee-booking/',
        '/employer/employee-profile/',
        '/employer/booking',
        '/employer/bookingdetails/',
        '/employee/booking',
        '/employee/booking-detail/',
        '/employee/schedule',
        '/employee/payments'
      ];
      if (urlsArray.indexOf(filterUrl) > 0) {
        this.removeShadow = true;
      } else {
        this.removeShadow = false;
      }
    });

    if (!this.startupService.startupData) {
      console.log('Some error form loading data');
    }

    this.dropdownList = this.startupService.startupData;
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select Skills',
      selectAllText: 'Select All',
      enableCheckAll: false,
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass greyPlaceholder'
    };

    this.messageservice.getrecepient().subscribe(recepient => {
      this.messageRecepients = recepient;
    });

    /* firebase message subscribe */
    this.firebaseservice.receiveMessage();
    this.messagevariable = this.firebaseservice.currentMessage;
    this.firebaseservice.currentMessage.subscribe(result => {
      if (result['data']) {
        this.notification_count = result.data['gcm.notification.badge_count'];
      }
    });
    this.messageservice.getNotification().subscribe(data => {
      this.notification_count = data;
    }); // subscribe for notifcations

    this.ModalSubcription = this.modalService.showModal$.subscribe(
      data => {
        // on success
        if (data['modal'] != 'privacyModal' && data['modal'] != 'termandcondition') {
          this.showModal(data['modal']);
          if (data['message']) {
            this.message = data['message'];
          }
        }
      },
      err => {
        //on error
      }
    );

    /**
     * @description login form with the parameters email and value
     */
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });

    this.forgetPasswordForm = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.pattern(this.emailPattern)]))
    });

    this.changePasswordForm = this.builder.group(
      {
        current_password: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(6)]],
        password_confirmation: ['', [Validators.required, Validators.minLength(6)]]
      },
      { validator: this.checkIfMatchingPasswords('password', 'password_confirmation') }
    );

    this.invoicepopupservice.getInvoice().subscribe(details => {
      this.invoiceDetails = details;
    });

    /* check browser */
    this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  }

  ngOnDestroy() {
    this.subscriptionEvents.unsubscribe();
  }

  ngAfterViewInit() {
    /* redirection url after login */
    this.router.queryParams.pipe(debounceTime(500)).subscribe(data => {
      if (data.returnUrl !== undefined || data.returnUrl != '') {
        this.returnUrl = data.returnUrl;
      }
    });
  }

  /**
   * @description function to hide menu
   */
  hideMenu() {
    this.show = false;
    this.showDropDownMenu = true;
    this.notificationMenu = true;
  }
  /* function ends here */

  /************* function to go back in mobile view  ********************/

  goback(type) {
    if (
      this.url.replace(/[0-9]/g, '') == '/employee/messages/' ||
      this.url.replace(/[0-9]/g, '') == '/employer/messages/'
    ) {
      this.route.navigateByUrl(this.url.replace(/[0-9]/g, '').replace(/\/$/, ''));
    } else if (
      this.previousroute.getCurrentUrls() == '/employee/booking' ||
      this.previousroute.getCurrentUrls() == '/employer/booking'
    ) {
      this.route.navigateByUrl('');
    } else {
      this.route.navigateByUrl(this.previousUrl);
    }
  }

  /************* function to go back in mobile view ends  ********************/

  getchk(event) {
    if (event.target.checked) {
      this.privacyButton = false;
    } else {
      this.privacyButton = true;
    }
  }
  resetimer() {
    this.userIdle.resetTimer();
  } // to reset autologout timer

  /**
   * @description menu toggling in responsive mode
   */

  /**
   * @description gives us the location of clicked place in document if user have clicked outside the header menu will be closed
   * else menu won't be closed
   * @param event
   */
  onClick(event) {
    if (!this.el.nativeElement.contains(event.target)) {
      // checking weather it is clicked inside the header or not
      this.show = false;
      this.showDropDownMenu = true;
      this.notificationMenu = true;
    }
  }

  /**
   * @description this function show the modal using renderer and elementref
   * @param modalName modal name to show
   */
  showModal(modalName, option?) {
    if (modalName == 'loginModalCandidate') {
      this.renderer.addClass(this.loginModalCandidate.nativeElement, 'show');
    } else if (modalName == 'loginModalEmployer') {
      this.renderer.addClass(this.LoginModalEmployer.nativeElement, 'show');
    } else if (modalName == 'thankyouModal') {
      this.renderer.addClass(this.thankyouModal.nativeElement, 'show');
    } else if (modalName == 'videoModal') {
      this.renderer.addClass(this.videoModal.nativeElement, 'show');
      const video = document.getElementsByTagName('iframe');
      video[0].src = 'https://www.youtube.com/embed/RSWTIIode7M';
    } else if (modalName == 'timeOutModal') {
      this.renderer.addClass(this.timeOutModal.nativeElement, 'show');
    } else if (modalName == 'forgetPassword') {
      this.renderer.addClass(this.forgetPassword.nativeElement, 'show');
    } else if (modalName == 'signOut') {
      this.renderer.addClass(this.signOut.nativeElement, 'show');
    } else if (modalName == 'changePasswordModal') {
      this.renderer.addClass(this.changePasswordModal.nativeElement, 'show');
    } else if (modalName == 'loader') {
      this.renderer.addClass(this.loader.nativeElement, 'show');
    } else if (modalName == 'sendMessageModal') {
      this.renderer.addClass(this.sendMessageModal.nativeElement, 'show');
    } else if (modalName == 'viewInvoiceModal') {
      this.renderer.addClass(this.viewInvoiceModal.nativeElement, 'show');
    } else if (modalName == 'firstTimePrivacyTerms') {
      this.renderer.addClass(this.firstTimePrivacyTerms.nativeElement, 'show');
      if (option == 'tiggerByHelp') {
        if ((document.querySelector('#privacyForms') as HTMLElement) != null) {
          (document.querySelector('#privacyForms') as HTMLElement).style.display = 'none';
        }
      }
    } else if (modalName == 'termandcondition') {
      this.modalService.onShowModal('termandcondition');
      this.hideModal('firstTimePrivacyTerms');
    } else if (modalName == 'privacyModal') {
      this.modalService.onShowModal('privacyModal');
    }
  }
  /** function ends here  */

  /**
   * @description hides the modal box
   * @param modalName modalname to hide the box
   */
  hideModal(modalName) {
    if (modalName == 'loginModalCandidate') {
      this.renderer.removeClass(this.loginModalCandidate.nativeElement, 'show');
    } else if (modalName == 'loginModalEmployer') {
      this.renderer.removeClass(this.LoginModalEmployer.nativeElement, 'show');
    } else if (modalName == 'videoModal') {
      const video = document.getElementsByTagName('iframe');
      video[0].src = '';
      this.renderer.removeClass(this.videoModal.nativeElement, 'show');
    } else if (modalName == 'thankyouModal') {
      this.renderer.removeClass(this.thankyouModal.nativeElement, 'show');

      if (this.message == 'Congratulations. Your profile has been successfully updated. Click OK to continue.') {
        this.userService.populate(); // populte on profile update in case of employer for set auth.
        this.message = '';
        this.route.navigateByUrl('');
      } else if (
        this.message == 'Thank you for your interest. Our representative will be in contact with you shortly.' ||
        this.message ==
          'Thanks for signing up. Your request has been sent for approval. We will notify you once approved.'
      ) {
        this.message = '';
        this.route.navigateByUrl('/');
      } else if (this.message == 'Password updated successfully.') {
        if (this.firstTimePrivacyVar == true) {
          this.renderer.addClass(this.firstTimePrivacyTerms.nativeElement, 'show');
        }
      }
    } else if (modalName == 'forgetPassword') {
      this.renderer.removeClass(this.forgetPassword.nativeElement, 'show');
    } else if (modalName == 'timeOutModal') {
      this.renderer.removeClass(this.timeOutModal.nativeElement, 'show');
    } else if (modalName == 'signOut') {
      this.renderer.removeClass(this.signOut.nativeElement, 'show');
    } else if (modalName == 'changePasswordModal') {
      this.renderer.removeClass(this.changePasswordModal.nativeElement, 'show');
    } else if (modalName == 'sendMessageModal') {
      this.renderer.removeClass(this.sendMessageModal.nativeElement, 'show');
    } else if (modalName == 'viewInvoiceModal') {
      this.renderer.removeClass(this.viewInvoiceModal.nativeElement, 'show');
    } else if (modalName == 'firstTimePrivacyTerms') {
      this.renderer.removeClass(this.firstTimePrivacyTerms.nativeElement, 'show');
      this.privacyButton = false;
    } else {
      this.renderer.removeClass(this.loader.nativeElement, 'show');
    }
  } /** function ends here */

  dontShowModal(modalname) {
    if (modalname == 'firstTimePrivacyTerms') {
      this.apiservice.get('/users/dont_show_suggestions').subscribe(data => {});
      this.firstTimePrivacyVar = false;
    }
    this.hideModal(modalname);
  }

  redirectByUrl(url) {
    if (url != '' && url != undefined) {
      if (url.includes('/messages/') == true) {
        this.messageservice.setrecepient(url.substring(url.lastIndexOf('/') + 1));
      }
      this.route.navigateByUrl(url);
    }
  }

  /**
   * @description checking password and confirm password
   * @param String passowrd
   * @param String confirm_password
   * @returns true else null
   */
  checkIfMatchingPasswords(password: string, passwordConfirm: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[password],
        passwordConfirmationInput = group.controls[passwordConfirm];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  } /** function ends here */

  //get notication function call

  getNotificationMsgs(currentPage, nativeUl?) {
    if (currentPage == 1 && nativeUl != undefined) {
      this.notificationArray = [];
      this.notificationLoader = true;

      setTimeout(() => {
        nativeUl.scrollTop = 0;
      }, 10);
    }

    if (this.notificationMenu == false) {
      const httpParams = new HttpParams().set('page', currentPage);
      httpParams.set('per_page', '6');

      this.apiservice.get('/conversations/get_all_notifications', httpParams).subscribe(data => {
        this.notificationLoader = false;

        if (data.status == true) {
          this.messageservice.setNotification(data.data.unread_notifications);
          this.CurrentPage = parseInt(data.page) + 1;
          if (currentPage == 1) {
            this.notificationArray = data.data.notifications;
          } else {
            data.data.notifications.filter(notification => {
              this.notificationArray.push(notification);
            });
          }
        }
      });
    }
  }

  /**
   * @description candidate form & employer form submit
   * @param String user form value
   */

  /** @description:- function to reset two form employer and candidate on close or any close event*/
  FormReset() {
    this.hideModal('loginModalCandidate');
    this.hideModal('loginModalEmployer');
    this.hideModal('forgetPassword');
    this.hideModal('changePasswordModal');
    this.errMessage = '';

    this.changePasswordForm.reset();
    this.forgetPasswordForm.reset();
    this.loginForm.reset();
    // this.candidateRegisterForm.controls['mobile_number_code'].setValue('+91');
    // this.employerRegisterForm.controls['mobile_number_code'].setValue('+91');
  } // function ends here

  onLoginForm(value): any {
    this.userService.attemptAuth('login', value).subscribe(
      data => {
        if (data['status'] === true) {
          if (data['data'].user.role_name == 'employee') {
            // if login by employee rediret t login

            if (this.returnUrl !== null && this.returnUrl != undefined && this.returnUrl.includes('/employee/')) {
              this.route.navigateByUrl(this.returnUrl);
              this.returnUrl = null;
            } else {
              this.route.navigateByUrl('employee');
            }
            this.headerEqualsTo = 'employee';
            this.UserName = data['data'].user.full_name;
          } else {
            if (this.returnUrl !== null && this.returnUrl != undefined && this.returnUrl.includes('/employer/')) {
              this.route.navigateByUrl(this.returnUrl);
              this.returnUrl = null;
            } else {
              this.route.navigateByUrl('employer');
            }
            this.headerEqualsTo = 'employer';
            //else redirect to employer
            this.UserName = data['data'].user.full_name;
          }
          this.messageservice.setNotification(data.data.user.unread_notifications); // set notification counts
          this.FormReset();
          if (data['data'].user['reset_password_required'] == true) {
            this.showModal('changePasswordModal');
            this.modalCloseButton.nativeElement.style.display = 'none';
            if (data['data'].user['first_time_login'] == true) {
              this.firstTimePrivacyVar = true;
            }
          } else {
            if (data['data'].user['first_time_login'] == true) {
              this.modalService.onShowModal('firstTimePrivacyTerms');
            }
          }

          //---set or update firebase token----//
          if (data.data.user.status == 'active') {
            this.firebaseservice.requestPermission(`User${data.data.user.id}`).then(tokenGet => {
              if (tokenGet) {
                //console.log(tokenGet);
                this.apiservice
                  .post('/users/store_device_token', { user: { device_type: 'browser', udid: tokenGet } })
                  .subscribe(data => {
                    if (data.status == true) {
                      localStorage.setItem('FcmToken', JSON.parse(JSON.stringify(tokenGet)));
                      this.firebaseservice.receiveMessage();
                    }
                  });
              }
            });
          }
        } else {
          this.FormReset();
          this.message = data['message'];
          this.showModal('thankyouModal');
        }
      },
      err => {},
      () => {}
    );
  }

  forgetPasswordSubmit(value) {
    if (this.forgetPasswordForm.valid) {
      this.showModal('loader');
      this.isFormSubmitting = true;
      this.userService.attemptAuth('forgot_password', value).subscribe(data => {
        this.hideModal('loader');
        this.isFormSubmitting = false;
        this.message = data['message'];
        this.showModal('thankyouModal');
        this.FormReset();
      });
    }
  }

  changePasswordFormSubmit(value) {
    if (this.changePasswordForm.valid) {
      this.isFormSubmitting = true;
      this.userService.attemptAuth('change_password', value).subscribe(data => {
        this.isFormSubmitting = false;

        if (data['status'] == true) {
          this.hideModal('changePasswordModal');
          this.modalCloseButton.nativeElement.style.display = 'block';
          this.FormReset();
        }
        this.showModal('thankyouModal');
        this.message = data['message'];
      });
    }
  }

  onLogout() {
    if (localStorage.getItem('FcmToken')) {
      let params = new HttpParams().set('device_type', 'browser');
      params = params.set('udid', localStorage.getItem('FcmToken'));
      this.employeeSearchService.saveSearchedValues();
      this.employeeSearchService.getsearchedEmployees();
      this.apiservice.get('/users/logout', params).subscribe(data => {});
      localStorage.removeItem('FcmToken');
    }
    this.userService.purgeAuth();
    this.route.navigateByUrl('/');
    this.hideModal('signOut');
    this.hideModal('changePasswordModal');
    localStorage.removeItem('filters');
  }

  public markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        Object.values(control['controls']).forEach((c: FormGroup) => {
          c.markAsTouched();
        });
      }
    });
  }

  setDate(value) {
    if (value) {
      return new Date(
        value
          .split('-')
          .reverse()
          .join('-')
      );
    }
  }

  shootMessage(messageForm: NgForm) {
    messageForm.control.markAsTouched();
    if (messageForm.valid && messageForm.control.touched && this.messageRecepients && this.messageTextBox.messageBox) {
      this.employerservice
        .add('/conversations', { body: this.messageTextBox.messageBox + '', recipient_id: this.messageRecepients })
        .subscribe(data => {
          if (data.status == true) {
            this.sendButtonDisable = true;
            this.hideModal('sendMessageModal');
            messageForm.reset();
            this.modalService.onShowModal('thankyouModal', data['message']);
            this.messageservice.clearrecepient();
          }
        });
    }
  }
  disableButton(event) {
    if (event.target.value != null && event.target.value != '') {
      this.sendButtonDisable = false;
    } else {
      this.sendButtonDisable = true;
    }
  }
}
