import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingServiceService {
  currentMessage = new BehaviorSubject<any>({});
  CurrentMessageObs = this.currentMessage.asObservable();
  tokenGenerated: string = null;

  constructor(private angularFireAuth: AngularFireAuth, private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messaging.subscribe(_messaging => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(() => {
      const data = {};
      data[userId] = token;
      // this.angularFireDB.object('fcmTokens/').update(data)
    });
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */

  requestPermission(userId) {
    return new Promise(resolve => {
      this.angularFireMessaging.requestToken
        .map(results => results)
        .subscribe(
          token => {
            this.tokenGenerated = token;
            if (this.tokenGenerated != null) {
              resolve(this.tokenGenerated);
              this.updateToken(userId, this.tokenGenerated);
            }
          },
          err => {
            console.error('Unable to get permission to notify.', err);
          }
        );
    });
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(payload => {
      this.currentMessage.next(payload);
    });
  }

  gettoken() {
    this.angularFireMessaging.getToken.subscribe(data => {});
  }
}
