import { Pipe, PipeTransform } from '@angular/core';
import { MessageServiceService } from '../../../shared/message-service.service';
import { Router } from '@angular/router';
import {NavigationEnd } from '@angular/router';

enum urls {
  "/employer" = "Dashboard",
  "/employer/search-employee" = "Search",
  "/employer/employee-profile/" = "Employee Profile",
  "/employer/shortlists" = "Shortlisted",
  "/employer/hiring-manager" = "Hiring Manager",
  "/employee" = "Dashboard",
  "/employee/complete-profile" = "Edit Profile",
  "/employee/schedule" = "Schedule",
  "/employer/editProfile" = "Edit Profile",
  "/employer/employee-booking/" = "Employee Booking",
  "/employer/messages" = "Messages",
  "/employee/messages" = "Messages",
  "/employee/payments" = "Payments",
  "/employee/booking-detail/" = "Booking Details",
  "/employer/bookingdetails/" = "Booking Details",
  "/employee/booking" = "Bookings",
  "/employer/booking" = "Bookings"
}

@Pipe({
  name: 'urlTransform'
})
export class UrlTransformPipe implements PipeTransform {

 name:any='';

  constructor(
    
    private messageservice:MessageServiceService,
    private router: Router
     ){

      this.router.events.subscribe((event) => {

        if (event instanceof NavigationEnd) {
  
          if (event.url && (event.url != '/employee/messages/' && event.url != 'employer/messages/')){ 
            this.messageservice.getmessageUser().subscribe(name=>{ this.name= name; })             
          }
        }
      });
  }

  transform(value: any, args?: any): any {
     let newvalue = value.replace(/[0-9]/g, '');
     if(newvalue != '/employee/messages/' && newvalue != '/employer/messages/'){  
    if(urls[newvalue] != undefined){
      value = urls[newvalue]
      return value;
    } } 
    else { 
      return this.name 
    }
    return null
   
  }
}
