import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MessageServiceService } from '../message-service.service';
import { UserService } from '../../core/services/user.service';
import { FirebaseMessagingServiceService } from '../firebase-messaging-service.service';
import { EmployerService } from '../../roles/employer/services/employer.service';
import { NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.css']
})
export class ChatBoxComponent implements OnInit, OnDestroy {

  @ViewChild('chatingBody') chatingBody: ElementRef;
  @ViewChild('chatingBodyMobile') chatingBodyMobile: ElementRef;

  messageInputText: any = {};
  chattingMessages: Array<any> = [];
  MyChatId: Number = 0;
  last_created_at = '';
  getData: {} = {};
  DirectChatUser = '';
  chatBoxScrollHeight: Number = null;
  UserChatId: any = null;
  responsivechatBoxes = true;
  chatUserObject: any = {};
  loginRole: any;
  dataCount = 0;
  chatBoxEnable = true;
  beforeMsg: string = null;
  subscriptions: any = null;

  constructor(
    private employerservice: EmployerService,
    private userService: UserService,
    private messageservice: MessageServiceService,
    private firebaseservice: FirebaseMessagingServiceService,
    private router: Router,

  ) {

    this.subscriptions = this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {

        this.loginRole = this.userService.getCurrentUser().role_name;

        if (event.url && event.url !== `${this.loginRole}/messages/`) {

          this.UserChatId = parseInt(event.url.replace(`/${this.loginRole}/messages/`, ''));
          if ((window.innerWidth < 767 && (this.UserChatId == null || this.UserChatId == '')) || isNaN(this.UserChatId)) { this.responsivechatBoxes = false; }
          if (this.UserChatId != null && !isNaN(this.UserChatId)) {
            this.chattingMessages = [];
            this.getChat(this.UserChatId, '');
            this.dataCount = 0; //On every route change set the data count to zero so that we can determine the request is given for first time
          }
        }

      }
    });

  }

  ngOnInit() {

    this.userService.currentUser$.subscribe(data => {
      if (data.status == 'active') {
        this.MyChatId = data.id;
      }
    });

    /* firebase message subscribe */
    this.firebaseservice.CurrentMessageObs.subscribe(message => {
      if (message.data && message.data['gcm.notification.sender_id'] == this.UserChatId) { this.getChat(this.UserChatId, ''); }
    });

    if (isNaN(this.UserChatId) || this.UserChatId == null) { this.beforeMsg = 'Please select a person to send message'; } else { this.beforeMsg = null; }

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setUser($event) {

    if (Object.keys($event).length !== 0) { this.chatUserObject = $event; }
  }

  messageShoot(messageform: NgForm) {

    this.employerservice.add('/conversations', { 'body': this.messageInputText.messageInput, recipient_id: this.UserChatId })
      .subscribe(data => {

        if (data.status == true) {
          this.getChat(this.UserChatId, '');
        }
      });

    messageform.reset();
  }

  getChat(chatId, lastdate) {

    if (lastdate == '') {
      this.chattingMessages = [];
    }

    if ((document.querySelector('#loaderid') as HTMLElement) != null) {
      (document.querySelector('#loaderid') as HTMLElement).style.display = 'block';
    }

    if ((document.querySelector('#loaderids') as HTMLElement) != null) {
      (document.querySelector('#loaderids') as HTMLElement).style.display = 'block';
    }


    if (chatId) {

      this.chatBoxEnable = false;

      this.employerservice.getEmprofile('/conversations/get_all_messages', { recipient_id: chatId, last_created_at: lastdate }).subscribe(data => {
        this.getData = data;

        //set username

        this.setUser({
          'other_user': data.user_name,
          'other_user_id': data.other_user_id,
          'other_user_organisation': data.user_organisation_name,
          'other_user_profile_pic': data.user_profile_pic
        });

        if ((document.querySelector('#loaderid') as HTMLElement) != null) {
          (document.querySelector('#loaderid') as HTMLElement).style.display = 'none';
        }
        if ((document.querySelector('#loaderids') as HTMLElement) != null) {
          (document.querySelector('#loaderids') as HTMLElement).style.display = 'none';
        }


        if (data.status == true) {

          this.dataCount = this.dataCount + 1;
          this.messageservice.clearrecepient();
          data.conversations.forEach(element => {
            if (this.chattingMessages.length == 0) {
              this.messageservice.setmessageUser(data.user_name);
              this.chattingMessages.push(element);
            } else {
              this.chattingMessages.unshift(element);
            }
          });

          this.last_created_at = data.last_created_at;

          if (window.innerWidth < 767) {

            if (this.chatingBodyMobile != undefined) {

              if (lastdate == '') {
                setTimeout(() => {
                  this.chatingBodyMobile.nativeElement.scrollTop = parseInt(this.chatingBodyMobile.nativeElement.scrollHeight);
                }, 100);
              } else {

                setTimeout(() => {
                  this.chatingBodyMobile.nativeElement.scrollTop = parseInt(this.chatingBodyMobile.nativeElement.scrollHeight) - (parseInt(this.chatingBodyMobile.nativeElement.scrollHeight) - 2700);
                }, 10);
              }
            }
          } else {

            if (lastdate == '') {
              setTimeout(() => {
                this.chatingBody.nativeElement.scrollTop = parseInt(this.chatingBody.nativeElement.scrollHeight);
              }, 100);
            } else {
              setTimeout(() => {
                this.chatingBody.nativeElement.scrollTop = parseInt(this.chatingBody.nativeElement.scrollHeight) - (parseInt(this.chatingBody.nativeElement.scrollHeight) - 2200);
              }, 10);

            }
          }
        }

      });
    }
  }

}
