import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MessageServiceService } from '../message-service.service';
import { UserService } from '../../core/services/user.service';
import { EmployerService } from '../../roles/employer/services/employer.service';
import { NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-chat-users',
  templateUrl: './chat-users.component.html',
  styleUrls: ['./chat-users.component.css']
})
export class ChatUsersComponent implements OnInit {
  messageInputText: any = {};
  chatingUsers: Array<any> = [];
  filterChatUsers: Array<any> = [];
  last_created_at = '';
  UserChatId: any = null;
  responsivechatBox = true;
  @Output() userObject = new EventEmitter<any>();
  loginRole: any;

  constructor(
    private employerservice: EmployerService,
    private userService: UserService,
    private messageservice: MessageServiceService,
    private router: Router,
    private changedetector: ChangeDetectorRef
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.loginRole = this.userService.getCurrentUser().role_name;

        if (event.url && event.url !== `${this.loginRole}/messages/`) {
          this.UserChatId = Number(event.url.replace(`/${this.loginRole}/messages/`, ''));
          if (window.innerWidth < 767) {
            this.responsivechatBox = false;
            if (this.UserChatId == null || this.UserChatId === '' || isNaN(this.UserChatId)) {
              this.responsivechatBox = true;
            }
          }
        }
      }
    });
  }

  ngOnInit() {
    this.getRecentUsers();
    this.loginRole = this.userService.getCurrentUser().role_name;
  }

  getRecentUsers() {
    if ((document.querySelector('#loaderIds') as HTMLElement) != null) {
      (document.querySelector('#loaderIds') as HTMLElement).style.display = 'block';
    }

    this.employerservice.getEmprofile('/conversations').subscribe(data => {
      this.changedetector.detectChanges();

      if ((document.querySelector('#loaderIds') as HTMLElement) != null) {
        (document.querySelector('#loaderIds') as HTMLElement).style.display = 'none';
      }

      if (data.status === true) {
        this.chatingUsers = data.conversations;
        this.filterChatUsers = data.conversations;
        if (this.filterChatUsers.length > 0) {
          this.filterChatUsers.filter(data => {});
        }
      }
    });
  }

  filterUsers(userInput) {
    if (userInput.length > 0) {
      this.filterChatUsers = this.chatingUsers.filter(user => {
        if (user.other_user.toLowerCase().search(userInput.toLowerCase()) > -1) {
          return user;
        }
      });
    } else {
      this.filterChatUsers = this.chatingUsers;
    }
  }

  chatShow(userObj) {
    if (Object.keys(userObj).length !== 0) {
      this.userObject.emit(userObj);
      this.messageservice.setmessageUser(userObj.other_user);
      this.router.navigateByUrl(`${this.loginRole}/messages/` + userObj.other_user_id);
      this.UserChatId = userObj.other_user_id;
    }
  }
}
