// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyD1nBCfUJUo8Jp_nWHyc6twLEK-EjYZ_hs',
    authDomain: 'doubluverified.firebaseapp.com',
    databaseURL: 'https://doubluverified.firebaseio.com',
    projectId: 'doubluverified',
    storageBucket: 'doubluverified.appspot.com',
    messagingSenderId: '1038386342803'
  },
  api_url: 'https://dev-mum.doublu.net/api'
  // api_url: 'http://192.168.3.69:3000/api'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
