import { Injectable } from '@angular/core';
import { Subject  } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

 showModal = new Subject<any>()
 showModal$ = this.showModal.asObservable();

 onShowModal(value,message?,eventstoclick?){
    this.showModal.next({modal:value,message:message,events:eventstoclick})
 }

}
