import { Component, OnInit, ElementRef, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../core/services/modal.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, AfterViewInit {
  @ViewChild('privacyModal') privacyModal: ElementRef;
  @ViewChild('confirmModal') confirmModal:ElementRef;
  @ViewChild('termandcondition') termandcondition:ElementRef;

  url: String;
  policyOpen: Boolean;
  constructor(
    private modalService: ModalService, 
    private renderer: Renderer2, 
    private activatedRoute: ActivatedRoute,
    private router: Router) {
  }

  confirmmessage= "Are You Sure ?";
  confirmFunc= "";
  year = new Date().getFullYear();

  ngOnInit() {
    this.modalService.showModal$.subscribe(
      data => {
        if (data['modal'] === 'privacyModal'){
          this.showModal('privacyModal');
        }else if(data['modal'] === 'confirmModal')
        {
          this.showModal('confirmModal',data['message'],data['events'])
        }else if(data['modal'] === 'termandcondition'){
          this.showModal('termandcondition');
        }
      }
    )
  }

  showModal(modalName,message?,events?) {
    
    if (modalName == 'privacyModal') {
      this.renderer.addClass(this.privacyModal.nativeElement, 'show')
      this.renderer.addClass(document.body,'body-overflow');
    }
    
    if(modalName == 'confirmModal'){

      this.confirmmessage= message;
      this.confirmFunc= events
      this.renderer.addClass(this.confirmModal.nativeElement, 'show')
    }
    if (modalName == 'termandcondition') {
      this.renderer.addClass(this.termandcondition.nativeElement, 'show')
      this.renderer.addClass(document.body,'body-overflow');
    }
  }
  

  hideModal(modalName) {

    if (modalName == 'privacyModal') {
      this.renderer.removeClass(document.body,'body-overflow');
      this.renderer.removeClass(this.privacyModal.nativeElement, 'show')
    }

    if (modalName == 'confirmModal') {
      this.renderer.removeClass(this.confirmModal.nativeElement, 'show')
    }
    if (modalName == 'termandcondition') {
      this.renderer.removeClass(document.body,'body-overflow');
      this.renderer.removeClass(this.termandcondition.nativeElement, 'show')
    }

  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(
      data => {
        if (data.policy == "true") {
          this.showModal("privacyModal");
        }else if(data.termandcondition == 'true'){
          this.showModal('termandcondition');
        
        }
      }
    )
  }

}
