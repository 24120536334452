import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { UserService } from './core/services';

@Injectable({
  providedIn: 'root'
})
export class PreventAuthGuard implements CanActivate {
  returnUrl;
  constructor(private route: Router, private userService: UserService, private router: ActivatedRoute) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    this.userService.currentUser$.subscribe(data => {
      if (Object.keys(data).length) {
        if (data.role_name === 'employee') {
          if (next.queryParams.returnUrl) {
            this.route.navigateByUrl(next.queryParams.returnUrl);
          } else {
            this.route.navigateByUrl('/employee');
          }
        } else {
          if (next.queryParams.returnUrl) {
            this.route.navigateByUrl(next.queryParams.returnUrl);
          } else {
            this.route.navigateByUrl('/employer');
          }
        }
      }
    });

    return true;
  }
}
