/**
 * All the common varibles used through out the projects
 */

export const COMMON = {
  alphaPattern: '^[A-Za-z ]+$',
  emailPattern: '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
  fullNamePattern: "^[a-zA-Z '.-]+$",
  abnMessage: 'ABN number should be valid and 11 digits long',
  sessionExpireMessage: 'Your session is about to expire.<br/>You will be logged out in 60 seconds.',
  fullNameValidationMessage: 'can only have alphabets, special characters ( - . ’ ) and should be between 3-50 characters.',
  roleValidation: 'Role can only have alphabets and should be between 3-50 characters.'
};

export const emailPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
