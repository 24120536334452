/**
 * This is a enum file
 * containing all the role constants
 * for the website
 */
export enum Roles {
    None = 'none',
    Admin = 'admin',
    SubAdmin = 'sub_admin',
    SubAdminTitle = 'Sub Admin',
    HiringManager = 'hiring_manager',
    HiringManagerTitle = 'Hiring Manager',
}
