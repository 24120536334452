export const indexMeta = [
  {
    name: 'title',
    content: `Contingent Workforce, Gig Economy, Future of the Workplace, Workforce Recruitment`
  },
  {
    name: 'Description',
    content: `Community Cafe is a technology platform enabling the future of corporate hiring for direct and on-demand resourcing. Community Cafe bridges the gap between hirers and workers in the gig economy.`
  }
];

export const aboutMeta = [
  {
    name: 'title',
    content: `Workforce Planning, Contract Jobs, Freelance Jobs India, Workforce Solutions`
  },
  {
    name: 'Description',
    content: `Community Cafe enables the gig economy through easy access for employers seeking highly skilled individuals and for workers seeking out their perfect gig.`
  }
];

export const howItWorkMeta = [
  {
    name: 'title',
    content: `Work In India, Online Hire, Workplace of the Future, Hiring Near Me`
  },
  {
    name: 'Description',
    content: `Community Cafe is a complete self-service platform for on-demand staffing. Registered employers can hire a worker in just a click of a button while corporate workers get hired on their own rates and schedules.`
  }
];

export const employeeMeta = [
  {
    name: 'title',
    content: `Smart Jobs, India Hiring, Job Finder App, Contract Jobs`
  },
  {
    name: 'Description',
    content: `Register now on Community Cafe's direct hiring platform to reclaim your independence and take control over where, when and how you work without ever needing to apply.`
  }
];

export const employerMeta = [
  {
    name: 'title',
    content: `Employee Online, Recruitment Online, Direct Recruitment, Staffing Solutions`
  },
  {
    name: 'Description',
    content: `Looking for a staffing solution that actually works? Register now on DoublU to directly access and book workers as per your needs without having to engage an agency.`
  }
];

export const contactMeta = [
  {
    name: 'title',
    content: `Future Workplace, On Demand Workforce, Gig Jobs India, Technology Workers`
  },
  {
    name: 'Description',
    content: `Want to find out how DoublU is changing the future of the workplace? We'd love to talk to you about it.`
  }
];
export const linkdinMeta =
{
  property: 'og:url',
  content: `https://www.doublu.com.au/`
}

