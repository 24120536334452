import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { ApiService } from '../core/services';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SkillsResolverService implements Resolve<any> {

  constructor(private apiService: ApiService) { }

  resolve() {
    return this.apiService.get('/skill_families').pipe(
      map( ({data}: {data}) => {
        const skills = data.skills;
       return skills.map((skill) => {
          return {
            id: skill.id,
            itemName: skill.name
          };
        });
      }),
      catchError(
        err => {
          
          return of('data not available at this time');;
        }
      )
    );
  }
}
