import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './index/index.component';
import { SkillsResolverService } from './index/skills-resolver.service';
import { EmployerAuthGuard } from './employer-auth.guard';
import { EmployeeAuthGuard } from './employee-auth.guard';
import { NotfoundComponent } from './notfound/notfound.component';
import { PreventAuthGuard } from './prevent-auth.guard';

const routes: Routes = [
  { path: '', component: IndexComponent, canActivate: [PreventAuthGuard] },
  { path: 'about', loadChildren: './about/about.module#AboutModule', canActivate: [PreventAuthGuard] },
  { path: 'contact', loadChildren: './contact/contact.module#ContactModule', canActivate: [PreventAuthGuard] },
  { path: 'index', component: IndexComponent, canActivate: [PreventAuthGuard] },
  {
    path: 'howitworks',
    loadChildren: './how-it-works/how-it-wroks.module#HowItWroksModule',
    canActivate: [PreventAuthGuard]
  },
  {
    path: 'employer',
    loadChildren: '../app/roles/employer/employer.module#EmployerModule',
    canActivate: [EmployerAuthGuard]
  },
  {
    path: 'employee',
    loadChildren: '../app/roles/employee/employee.module#EmployeeModule',
    canActivate: [EmployeeAuthGuard]
  },
  {
    path: 'employee-register',
    loadChildren: './employee-register/employee-register.module#EmployeeRegisterModule',
    canActivate: [PreventAuthGuard]
  },
  {
    path: 'employer-register',
    loadChildren: './employer-register/employer-register.module#EmployerRegisterModule',
    canActivate: [PreventAuthGuard]
  },
  { path: '404', component: NotfoundComponent },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [SkillsResolverService]
})
export class AppRoutingModule {}
