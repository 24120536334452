import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ApiService } from '../core/services/api.service'
import { HttpParams } from '@angular/common/http';

@Injectable()
export class PreviousRouteService {

  private previousUrl: string ='/';
  private currentUrl: string = '/';

  constructor(private router: Router, private apiservice:ApiService) {
    this.currentUrl = this.router.url;

    
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {          
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;        
      };
      
    });
  }

  public getPreviousUrl() { 
    return this.previousUrl;
  }    

  public getCurrentUrls(){
    return this.currentUrl;
  }
}