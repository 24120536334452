import { Injectable } from '@angular/core';
import { ModalService } from '../../core/services/modal.service'; 
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class InvoicePopupService {

  constructor(private moadlservice: ModalService) { }


  // Observable string sources
  private invoiceSource = new Subject<string>();

  // Service message commands
  newInvoice(invoiceObject){ 
    
    this.invoiceSource.next(invoiceObject);
  }

  getInvoice(){
    return this.invoiceSource.asObservable();
  }

  emptyInvoice()
  {
    this.invoiceSource.next();
  }

}
