import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService, UserService } from '../core';
import { Meta, Title } from '@angular/platform-browser';
import { indexMeta, linkdinMeta } from '../shared/metaTag.const';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  /** these are the static data for the carousel used in website */

  public imageSources = [
    {
      image: "files/images/customers-icon/Assembly.png",
      feedback: '“Hiring is expensive and laborious! Does it need to be that hard?”',
      representative: 'Assembly',
      url: 'https://assemblypayments.com/',
      class: 'image-1'
    },
    {
      image: "files/images/customers-icon/AttentionExperts.png",
      feedback: '“My agencies don’t necessarily deliver good people. Wish I could access the guys I need directly”',
      representative: 'Attention Experts',
      url: 'https://attentionexperts.com/',
      class: 'image-2'
    },
    {
      image: "files/images/customers-icon/Covau.png",
      feedback: '"My contingent workforce is expensive and laborious. The administration is killing me"',
      representative: 'Covau',
      url: 'https://www.covau.com.au/',
      class: 'image-3'
    },
    {
      image: "files/images/customers-icon/Cyberdots.png",
      feedback: '“What I’m doing now doesn’t align to what I want to do”',
      representative: 'Cyberdots',
      url: 'https://cyberdots.com.au/',
      class: 'image-4'
    },
    {
      image: "files/images/customers-icon/Designeri.png",
      feedback: '“I am way too busy, I wish I had more leaves”',
      representative: 'Designeri',
      url: 'https://www.designeri.am/',
      class: 'image-5'
    },
    {
      image: "files/images/customers-icon/Nihilent.png",
      feedback: '“I am way too busy, I wish I had more leaves”',
      representative: 'Nihilent',
      url: 'https://nihilent.com/',
      class: 'image-6'
    },

    {
      image: "files/images/customers-icon/prospecta-logo.svg",
      feedback: '“I am way too busy, I wish I had more leaves”',
      representative: 'Prospecta',
      url: 'https://www.prospecta.com/',
      class: 'image-7'
    },
    {
      image: "files/images/customers-icon/Seers.svg",
      feedback: '“I am way too busy, I wish I had more leaves”',
      representative: 'Seers',
      url: 'https://www.seers.digital/',
      class: 'image-8'
    },

    {
      image: "files/images/customers-icon/serole-logo1.png",
      feedback: '“I am way too busy, I wish I had more leaves”',
      representative: 'Serole',
      url: 'https://www.serole.com/',
      class: 'image-9'
    },

    {
      image: "files/images/customers-icon/Topline.png",
      feedback: '“I am way too busy, I wish I had more leaves”',
      representative: 'Topline',
      url: 'http://www.toplineco.com.au',
      class: 'image-10'
    },

    {
      image: "files/images/customers-icon/VTT.png",
      feedback: '“I am way too busy, I wish I had more leaves”',
      representative: 'VTT',
      url: 'https://vttsolutions.com.au/',
      class: 'image-11'
    }
  ];
  sliderOptions = {
    margin: 10,
    nav: true,
    autoplay: true,
    autoplayHoverPause: true,
    loop: true,
    lazyLoad: true,
    autoplayTimeout: 1000,
    responsive: { 0: { items: 1 }, 600: { items: 2 }, 1000: { items: 4 } }
  };
  sliderOptions2 = {
    items: 6,
    dots: false,
    nav: false,
    loop: true,
    autoplayHoverPause: true,
    responsiveClass: true,
    autoplay: true,
    margin: 10,
    autoplayTimeout: 2000,
    responsive: { 0: { items: 1 }, 360: { items: 2 }, 600: { items: 3 }, 1024: { items: 5 }, 1200: { items: 6 }}
  };

  constructor(
    private userService: UserService,
    private modalService: ModalService,
    private route: Router,
    private router: ActivatedRoute,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle(indexMeta[0].content);
    this.meta.updateTag({ name: indexMeta[0].name, content: indexMeta[0].content }, `name='title'`);
    this.meta.updateTag({ name: indexMeta[1].name, content: indexMeta[1].content }, `name='Description'`);
    this.meta.updateTag({ property: linkdinMeta.property, content: linkdinMeta.content }, `property='og:url'`);
  }

  public topService = [
    { img: 'files/images/Client_logo_1.png', name: 'Airbus SE' },
    { img: 'files/images/Client_logo_2.png', name: 'Daikin Industries, Ltd' },
    { img: 'files/images/Client_logo_3.png', name: 'Schlumberger Limited' },
    { img: 'files/images/Client_logo_4.png', name: 'LG Corporation' },
    { img: 'files/images/Client_logo_3.png', name: 'Ramola Daniel' }
  ];

  ngOnInit() {
    const roleName = this.userService.getCurrentUser() ? this.userService.getCurrentUser().role_name : undefined;

    if (roleName !== undefined && roleName == 'employee') {
      this.route.navigateByUrl('/employee');
    } else if (roleName !== undefined && roleName == 'employer') {
      this.route.navigateByUrl('/employer');
    }
  } /** ngOnInit ends here  */

  showModal(modalName) {
    this.modalService.onShowModal(modalName);
  }
  onNavigate(url) {
    console.log(url)
    window.open(url, "_blank");
  }
}
