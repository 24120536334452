import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EmployeeAuthGuard implements CanActivate {
  roleName;
  constructor(private userService: UserService, private route: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const roleName = this.userService.getCurrentUser().role_name;
    if (roleName !== undefined && roleName === 'employee') {
      return true;
    } else if (roleName !== undefined && roleName === 'employer') {
      this.route.navigateByUrl('/employer');
    } else {
      this.route.navigate(['/'], { queryParams: { returnUrl: state.url } });
    }

    return false;
  }
}
