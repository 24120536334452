import { Injectable } from '@angular/core';
import { ApiService, UserService } from '../../../core';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class EmployerService {

  constructor(private apiService: ApiService, private userservice: UserService) { }
  public message = new BehaviorSubject({})

  add(path, value) {
    return this.apiService.post(path, value)
  }

  search_empl(path, value) {
    return this.apiService.post(path, value);
  }

  getEmprofile(path, value?) {
    return this.apiService.get(path, value);
  }

  validate(abn_number) {
    return this.userservice.validateAbn(abn_number)
  }

  updatEmProfile(details) {
    const path = '/employers/update_hiring_manager'
    return this.apiService.post(path, details);
  }
}
