import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class EmployeeSearchService {

  constructor() { }
  searchedValues = new BehaviorSubject({});
  searchData = new BehaviorSubject({});
  SearchedEntity = this.searchData.asObservable();

  getsearchedEmployees(employees = {}) {
    this.searchData.next(employees);

  }

  saveSearchedValues(values = {}) {
    this.searchedValues.next(values);
  }

}
